import { GameState, Player } from './state';

export function player(state: GameState, userId: string): Player | undefined {
  return state.players.find((p) => p.userId === userId);
}

export function totalDrinks(state: GameState): number {
  return state.players.reduce((memo, player) => memo + player.drinks, 0);
}

export function nameIsTaken(state: GameState, displayName: string): boolean {
  return !!state.players.find(
    (p) => p.displayName.toLowerCase() === displayName.toLowerCase(),
  );
}
