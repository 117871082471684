import { produce } from 'immer';
import { GameState } from './state';
import { Action, DRINK, JOIN_GAME } from './actions';
import { nameIsTaken, player } from './selectors';

export type ReducerContext = {
  isClient: boolean;
};

export default produce(
  (state: GameState, action: Action, context: ReducerContext) => {
    switch (action.type) {
      case DRINK: {
        const { userId, drinks } = action.payload;

        const playerData = player(state, userId);

        if (playerData) {
          playerData.drinks += drinks ?? 1;

          if (playerData.drinks < 0) {
            playerData.drinks = 0;
          }
        }

        break;
      }
      case JOIN_GAME: {
        if (context.isClient) {
          // no client-side simulation
          return;
        }

        const { userId, displayName } = action.payload;
        const playerData = player(state, userId);

        if (playerData) {
          playerData.displayName = displayName;
        } else {
          // Make sure the name isn't taking
          if (nameIsTaken(state, displayName)) {
            return;
          }

          state.players.push({
            displayName: displayName,
            drinks: 0,
            userId: userId,
          });
        }
      }
    }
  },
);
