import { State } from './types';
import {
  Action,
  CONNECTED,
  DISCONNECTED,
  GAME_ACTION,
  GAME_DATA_UPDATE,
} from './actions';
import { gameReducer } from 'rum-common';
import { gameState } from './selectors';

export default function reducer(state: State = {}, action: Action): State {
  switch (action.type) {
    case CONNECTED:
      return {
        ...state,
        connected: true,
        disconnectedAt: undefined,
      };
    case DISCONNECTED:
      return {
        ...state,
        connected: false,
        disconnectedAt: Date.now(),
      };
    case GAME_ACTION:
      return {
        ...state,
        gameData: {
          gameState: gameReducer({ isClient: true })(
            gameState(state),
            action.payload,
          ),
          users: state.gameData?.users ?? [],
        },
      };
    case GAME_DATA_UPDATE:
      return {
        ...state,
        hasInitialData: true,
        gameData: action.payload,
      };
    default:
      return state;
  }
}
