import { State } from './types';
import { GameState, initialGameState } from 'rum-common';

export function isDisconnected(state: State): boolean {
  return !state.connected;
}

export function gameState(state: State): GameState {
  return state.gameData?.gameState ?? initialGameState();
}

export function initalLoadComplete(state: State): boolean {
  return !!state.hasInitialData;
}
