import { Actions as GameActions, GameState } from 'rum-common';
import { GameData } from '../libgamestate/types';

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const GAME_ACTION = 'GAME_ACTION';
export const GAME_DATA_UPDATE = 'GAME_DATA_UPDATE';

interface ConnectedAction {
  type: typeof CONNECTED;
}

interface DisconnectedAction {
  type: typeof DISCONNECTED;
}

interface GameActionAction {
  type: typeof GAME_ACTION;
  payload: GameActions.Action;
}

interface GameDataUpdateAction {
  type: typeof GAME_DATA_UPDATE;
  payload: GameData<GameState>;
}

export type Action =
  | ConnectedAction
  | DisconnectedAction
  | GameActionAction
  | GameDataUpdateAction;

export function connected(): ConnectedAction {
  return { type: CONNECTED };
}

export function disconnected(): DisconnectedAction {
  return { type: DISCONNECTED };
}

export function gameAction(action: GameActions.Action): GameActionAction {
  return { type: GAME_ACTION, payload: action };
}

export function gameDataUpdate(
  state: GameData<GameState>,
): GameDataUpdateAction {
  return { type: GAME_DATA_UPDATE, payload: state };
}
