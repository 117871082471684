import { createStore } from 'redux';
import reducer from './data/reducer';
import { initialGameState } from 'rum-common';

export default createStore(
  reducer,
  {
    gameData: {
      gameState: initialGameState(),
      users: [],
    },
  },
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
