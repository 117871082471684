import React, { useRef, useState, FormEvent } from 'react';
import './SignIn.css';
import { useSelector } from 'react-redux';
import * as selectors from '../data/selectors';
import { Selectors as GameSelectors } from 'rum-common';
import userId from '../userId';

type Props = {
  onSignIn: (displayName: string) => void;
};

export default function SignIn({ onSignIn }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const nameIsTaken = useSelector((state) => (displayName: string) =>
    GameSelectors.nameIsTaken(selectors.gameState(state), displayName),
  );

  const didSignIn = useSelector(
    (state) => !!GameSelectors.player(selectors.gameState(state), userId()),
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  async function handleJoin(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    if (isLoading || !inputRef.current?.value) {
      return;
    }

    setIsLoading(true);
    try {
      await onSignIn(inputRef.current.value);

      // if (!didSignIn) {
      //   alert('Error joining game. Please try again.');
      // }
    } catch (e) {
      console.error('Error joining', e);
      alert('Error joining game. Please try again.');
    }

    setIsLoading(false);
  }

  function validateDisplayName() {
    if (!inputRef.current) {
      return;
    }

    if (nameIsTaken(inputRef.current.value)) {
      inputRef.current.setCustomValidity('That display name is taken');
    } else {
      inputRef.current.setCustomValidity('');
    }
  }

  return (
    <form onSubmit={handleJoin} className="display-name-form">
      <input
        type="text"
        ref={inputRef}
        maxLength={8}
        required
        placeholder="Display Name"
        onInput={validateDisplayName}
        autoFocus
      />
      <br />
      <input
        type="submit"
        value={isLoading ? 'Joining...' : 'Join Game'}
        disabled={isLoading}
      />
    </form>
  );
}
