import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GameState, Actions as GameActions } from 'rum-common';

import {
  gameAction,
  gameDataUpdate,
  connected,
  disconnected,
} from './data/actions';

import ServerlessGameStateClient from './libgamestate/ServerlessGameStateClient';
import { GameData } from './libgamestate/types';

import Game from './Game';
import './App.css';
import userId from './userId';

export default function GameContainer({ gameId }: { gameId: string }) {
  const [client, setClient] = useState<ServerlessGameStateClient<
    GameActions.Action,
    GameState
  > | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const client = new ServerlessGameStateClient({
      httpURL: process.env.REACT_APP_HTTP_API!,
      websocketURL: process.env.REACT_APP_WS_API!,
      gameId,
      userId: userId(),
      actionRetryInterval: 1000,
      actionTimeout: 5000,
      wsReconnectInterval: 1000,
      simulateAction(action: GameActions.Action) {
        dispatch(gameAction(action));
      },
      onReceiveState(data: GameData<GameState>) {
        dispatch(gameDataUpdate(data));
      },
      onConnect() {
        dispatch(connected());
      },
      onDisconnect() {
        dispatch(disconnected());
      },
    });
    setClient(client);

    client.connect();
    return () => client.close();
  }, [gameId, dispatch]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return <Game client={client} />;
}
