import reducer from './reducer';
import type { ReducerContext } from './reducer';
import * as Selectors from './selectors';
import * as Actions from './actions';
import type { GameState, Player } from './state';

export function gameReducer(context: ReducerContext) {
  return (state: GameState, action: Actions.Action) =>
    reducer(state, action, context);
}

export { Selectors, Actions };
export type { GameState, Player, ReducerContext };
export { initialGameState } from './state';
