import React from 'react';
import { Player } from 'rum-common';
import './Players.css';

export default function Players({ players }: { players: Player[] }) {
  const sortedPlayers = players
    .filter((p) => p.drinks > 0)
    .sort((a, b) => b.drinks - a.drinks);

  return (
    <>
      <h2>Leaderboard</h2>
      <div className="players">
        {sortedPlayers.map((player) => (
          <div className="player">
            <div className="player-name">{player.displayName}</div>
            <div className="player-drinks">{player.drinks}</div>
          </div>
        ))}
      </div>
    </>
  );
}
