export type Player = {
  userId: string;
  displayName: string;
  drinks: number;
};

export type GameState = {
  players: Player[];
};

export function initialGameState(): GameState {
  return {
    players: [],
  };
}
