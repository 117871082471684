export const DRINK = 'DRINK';
export const JOIN_GAME = 'JOIN_GAME';

export type DrinkAction = {
  type: typeof DRINK;
  payload: {
    userId: string;
    drinks?: number;
  };
};

export type JoinGameAction = {
  type: typeof JOIN_GAME;
  payload: {
    userId: string;
    displayName: string;
  };
};

export type Action = DrinkAction | JoinGameAction;

export function drink(userId: string, drinks: number): DrinkAction {
  return {
    type: DRINK,
    payload: {
      userId,
      drinks,
    },
  };
}

export function joinGame(userId: string, displayName: string): JoinGameAction {
  return {
    type: JOIN_GAME,
    payload: {
      userId,
      displayName,
    },
  };
}
