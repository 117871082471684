import React from 'react';
import './App.css';
import userId from './userId';
import * as selectors from './data/selectors';
import { useSelector } from 'react-redux';
import { Actions, Selectors as GameSelectors } from 'rum-common';
import SignIn from './components/SignIn';
import ServerlessGameStateClient from './libgamestate/ServerlessGameStateClient';

import { GameState, Actions as GameActions } from 'rum-common';
import Bottle from './components/Bottle';
import Players from './components/Players';

const TOTAL_DRINKS = 40;

export default function Game({
  client,
}: {
  client: ServerlessGameStateClient<GameActions.Action, GameState>;
}) {
  const gameState = useSelector(selectors.gameState);
  const initialLoadComplete = useSelector(selectors.initalLoadComplete);

  if (!initialLoadComplete) {
    return <div>Loading...</div>;
  }

  const myPlayer = GameSelectors.player(gameState, userId());

  function handleSignIn(displayName: string) {
    return client.runAction(Actions.joinGame(userId(), displayName));
  }

  function handleDrink(n: number) {
    client
      .runAction(Actions.drink(userId(), n))
      .catch((e) => console.log('ACTION FAILED', e));
  }

  if (!myPlayer) {
    // We haven't signed in yet
    return <SignIn onSignIn={handleSignIn} />;
  }

  const drinksLeft = TOTAL_DRINKS - GameSelectors.totalDrinks(gameState);
  const rumPercent = drinksLeft / TOTAL_DRINKS;

  return (
    <div>
      <div className="bottle-wrapper">
        <Bottle percent={rumPercent} />
      </div>
      <div className="drink-counter">
        {drinksLeft > 0 ? (
          <span>{drinksLeft} Shots Left</span>
        ) : (
          <span>WHY IS THE RUM GONE?</span>
        )}
      </div>
      <div className="actions">
        <button onClick={() => handleDrink(1)}>I DRANK</button>
        <br />
        <button className="small-button" onClick={() => handleDrink(-1)}>
          Undo Drink
        </button>
      </div>
      <Players players={gameState.players} />
    </div>
  );
}
