import { v4 as uuidv4 } from 'uuid';
import { ServerlessGameStateClientConfig, GameData } from './types';

type WSMessage<State> = {
  gameData: GameData<State>;
  actionNonces: string[];
};

type ActionRecord<Action> = {
  nonce: string;
  state: 'sending' | 'sent' | 'failed' | 'acked';
  action: Action;
  promise: { resolve: () => void; reject: (err: Error) => void };
};

export type ServerlessGameStateClientError = Error & { code: string };

function errorWithCode(
  code: string,
  message: string,
): ServerlessGameStateClientError {
  const err = new Error(message) as any;
  err.code = code;
  return err;
}

function promiseAndCallbacks(): {
  promise: Promise<void>;
  resolve: () => void;
  reject: (err: Error) => void;
} {
  let resolve: any;
  let reject: any;
  const promise = new Promise<void>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { resolve, reject, promise };
}

export default class ServerlessGameStateClient<Action, State> {
  config: ServerlessGameStateClientConfig<Action, State>;
  state:
    | 'initializing'
    | 'connecting'
    | 'connected'
    | 'reconnecting'
    | 'disconnected'
    | 'closed' = 'initializing';
  ws: WebSocket | null = null;
  reconnectTimeout: number | undefined;
  pendingActions: ActionRecord<Action>[] = [];
  hasReceivedInitialState: boolean = false;
  lastReceivedData: GameData<State> | null = null;

  constructor(config: ServerlessGameStateClientConfig<Action, State>) {
    this.config = config;
  }

  connect() {
    this.state = 'connecting';
    this.tryConnect();
  }

  private tryConnect() {
    // Connect websocket
    this.ws = new WebSocket(
      `${this.config.websocketURL}?gameId=${this.config.gameId}&userId=${this.config.userId}`,
    );

    this.ws.onopen = () => {
      if (this.state === 'closed') {
        // client was closed while we were connecting
        this.ws!.close();
        return;
      }

      this.state = 'connected';
      this.config.onConnect();

      // Send a hello so that we get the initial state
      this.ws!.send(JSON.stringify({ msg: 'hello' }));
    };

    this.ws.onclose = (event) => {
      if (this.state === 'closed') {
        // client was closed while we were connected
        return;
      }

      if (this.state === 'connected') {
        // we used to be connected; let the app know
        this.config.onDisconnect();
      }

      console.log(
        `[ServerlessGameStateClient] Disconnected; will reconnect in ${this.config.wsReconnectInterval} milliseconds`,
      );

      this.state = 'disconnected';
      this.reconnectTimeout = window.setTimeout(() => {
        this.state = 'reconnecting';
        this.tryConnect();
      }, this.config.wsReconnectInterval);
    };

    this.ws.onmessage = (event) => {
      if (this.state === 'closed') {
        // This shouldn't happen
        return;
      }

      const { gameData, actionNonces } = JSON.parse(event.data) as WSMessage<
        State
      >;
      this.lastReceivedData = gameData;

      let isInitialStateOnConnect = !this.hasReceivedInitialState;
      const ackedPromiseCompletes: (() => void)[] = [];

      if (this.hasReceivedInitialState) {
        // clean up any action that are ack'd by this message
        this.pendingActions = this.pendingActions.filter((action) => {
          const isAcked = actionNonces.includes(action.nonce);
          if (isAcked) {
            ackedPromiseCompletes.push(action.promise.resolve);
            action.state = 'acked';
          }

          return !isAcked;
        });
      } else {
        // we don't know what messages were processed while we were offline --
        // we just assume everything we've successfully sent to the server was
        // processed
        this.hasReceivedInitialState = true;
        this.pendingActions = this.pendingActions.filter(
          (action) => action.state === 'sending',
        );
      }

      // Deliver the state update
      this.config.onReceiveState(gameData, {
        isInitialStateOnConnect,
        isRevert: false,
        pendingActions: this.pendingActions.map((a) => a.action),
      });

      // Re-simulate any pending actions
      this.pendingActions.forEach((action) => {
        this.config.simulateAction(action.action, {
          isInitialSimulation: false,
        });
      });

      // Resolve promises for processed actions
      ackedPromiseCompletes.forEach((c) => c());
    };
  }

  close() {
    this.state = 'closed';
    this.hasReceivedInitialState = false;

    if (this.ws) {
      this.ws.close();
    }

    if (this.reconnectTimeout) {
      window.clearTimeout(this.reconnectTimeout);
    }

    // clean up all pending actions
    this.pendingActions.forEach((action) => {
      if (action.state === 'sending') {
        action.promise.reject(
          errorWithCode(
            'ConnectionClosedWhileSending',
            'The websocket connection closed while sending the action',
          ),
        );
        action.state = 'failed';
      } else if (action.state === 'sent') {
        action.promise.resolve();
      }
    });
    this.pendingActions = [];
  }

  runAction(action: Action) {
    if (this.state !== 'connected') {
      throw errorWithCode(
        'ClientIsNotConnected',
        `Cannot runAction when game state client is ${this.state}`,
      );
    }

    // First, run a local simulation of the action
    this.config.simulateAction(action, {
      isInitialSimulation: true,
    });

    // Then, submit it to the server
    const { resolve, reject, promise } = promiseAndCallbacks();
    const nonce = uuidv4();

    const actionRecord: ActionRecord<Action> = {
      nonce,
      state: 'sending',
      action,
      promise: { resolve, reject },
    };
    this.pendingActions.push(actionRecord);

    let tryCount = 0;

    const trySend = async () => {
      if (actionRecord.state !== 'sending') {
        // something else changed the send state -- probably a timeout
        return;
      }

      tryCount++;
      try {
        const result = await fetch(
          `${this.config.httpURL}/games/${this.config.gameId}/actions`,
          {
            method: 'POST',
            body: JSON.stringify({ nonce, action }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (actionRecord.state !== 'sending') {
          // something else changed the send state -- probably a timeout
          return;
        }

        if (result.status >= 400) {
          throw new Error(`Got bad status code: ${result.status}`);
        }

        // Success!
        actionRecord.state = 'sent';
      } catch (e) {
        console.log(
          `Failed to submit action; will retry in ${this.config.actionRetryInterval} milliseconds`,
          e,
        );
        setTimeout(trySend, this.config.actionRetryInterval);
      }
    };

    // Set up the timeout
    setTimeout(() => {
      if (actionRecord.state === 'acked' || actionRecord.state === 'failed') {
        // succeded or failed aready
        return;
      }

      console.warn(`Action timed out after ${tryCount} tries`, action);

      actionRecord.state = 'failed';

      // Remove from pending actions
      this.pendingActions = this.pendingActions.filter(
        (action) => action.nonce !== nonce,
      );

      // Roll back state
      if (this.lastReceivedData) {
        this.config.onReceiveState(this.lastReceivedData, {
          isInitialStateOnConnect: false,
          isRevert: true,
          pendingActions: this.pendingActions.map((a) => a.action),
        });

        // Re-simulate any pending actions
        this.pendingActions.forEach((action) => {
          this.config.simulateAction(action.action, {
            isInitialSimulation: false,
          });
        });
      }

      // Reject promise
      reject(
        errorWithCode(
          'ActionPostTimedOut',
          'Timed out while trying to submit action',
        ),
      );
    }, this.config.actionTimeout);

    // Start sending and return the promise
    trySend();
    return promise;
  }
}
