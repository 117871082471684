import React, { useState, useRef } from 'react';
import './Bottle.css';

const FULL = 0.71429;
const EMPTY = 0.03189;
const ANIMATION_TIME = 500;

function easeInOutQuad(t: number): number {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

function computeFill(percent: number): number {
  return EMPTY + percent * (FULL - EMPTY);
}

function cssFor(fillLevel: number): { [key: string]: string } {
  const fillLevelPct = `${(fillLevel * 100).toFixed(3)}%`;

  return {
    background: `linear-gradient(0deg, #9D702E ${fillLevelPct}, white ${fillLevelPct})`,
    transition: 'all 0.5s ease',
  };
}

export default function Bottle({ percent }: { percent: number }) {
  const [fill, setFill] = useState(computeFill(percent));

  const requestRef = useRef<null | number>(null);

  React.useEffect(() => {
    const startFill = fill;
    const endFill = computeFill(percent);
    const startTime = performance.now();

    const animate = () => {
      const progress = (performance.now() - startTime) / ANIMATION_TIME;
      if (progress > 1) {
        setFill(endFill);
        return;
      }

      setFill(startFill + easeInOutQuad(progress) * (endFill - startFill));
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percent]); // Make sure the effect runs only once

  return (
    <img
      className="bottle"
      src="/bottle.png"
      style={cssFor(fill)}
      alt="Rum Bottle"
    />
  );
}
