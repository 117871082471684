import React, { useState } from 'react';
import './App.css';
import GameContainer from './GameContainer';
import { v4 as uuidv4 } from 'uuid';
//z
export default function App() {
  const [gameId, setGameId] = useState(document.location.hash.slice(1));

  if (gameId) {
    return <GameContainer gameId={gameId} />;
  }

  function createNewGame() {
    const gameId = uuidv4();
    document.location.hash = `#${gameId}`;
    setGameId(gameId);
  }

  return (
    <div className="app">
      <p>To join an existing game, ask a player for their URL.</p>
      <p>
        Or <button onClick={createNewGame}>Create A New Game</button>
      </p>
    </div>
  );
}
